<template>
  <div class="footerBox">
    <div class="container">
      <div class="foterpic">
        <img
          :src="logo"
          :style="{ width: $i18n.locale === 'en-US' ? '100px' : '60px' }"
        />
      </div>
      <div class="partLink">
        <div>
          <router-link :to="{ path: '/aboutUs' }">{{
            $t("lang.footer.titleOne")
          }}</router-link>
        </div>
        <div>
          <router-link :to="{ path: '/trade' }">{{
            $t("lang.footer.titleTwo")
          }}</router-link>
        </div>
        <div>
          <router-link :to="{ path: '/brandCenter' }">{{
            $t("lang.footer.titleThree")
          }}</router-link>
        </div>
        <div>
          <router-link :to="{ path: '/joinUs' }">{{
            $t("lang.footer.titleFour")
          }}</router-link>
        </div>
      </div>
      <div class="footerWx">
        <img src="../assets/gongzong.png" style="margin-right: 20px" />
        <img src="../assets/map.png" />
      </div>
    </div>
    <div class="footer-item">
      <a href="https://beian.miit.gov.cn" target="_blank" class="link">
        2021 ©one-lime.com 沪ICP备2021022268号️-1
      </a>
    </div>
  </div>
</template>
<script>
import chaLogo from "@/assets/logo-1.jpg";
import enLogo from "@/assets/eng_footer.jpg";
export default {
  name: "pcFooter",
  data() {
    return {
      logo: "",
      chaLogo,
      enLogo,
    };
  },
  created() {
    const language = sessionStorage.getItem("language") || "zh-CN";
    if (language === "zh-CN") {
      this.logo = chaLogo;
    } else {
      this.logo = enLogo;
    }
  },
  watch: {
    "$i18n.locale": function (newVal) {
      if (newVal === "en-US") {
        this.logo = enLogo;
      } else {
        this.logo = chaLogo;
      }
    },
  },
};
</script>
<style lang="scss">
.footerBox {
  width: 100%;
  padding: 30px 0;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    .partLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 800px;
      margin: 0 auto;
      div {
        margin-right: 20px;
        a {
          color: #7f7f7f;
          font-size: 14px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .footer-item {
    color: #7f7f7f;
    font-size: 14px;
    margin-top: 20px;
  }
}
.link {
  color: #7f7f7f;
}
</style>
