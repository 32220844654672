<template>
  <div class="header" :class="isfixed ? 'toFixed' : ''">
    <div class="headerContainer">
      <div class="chLogo" :class="$i18n.locale === 'en-US' ? 'enLogo' : ''">
        <img
          :src="logo"
          alt=""
          @click="$router.push({ path: '/home' })"
          style="cursor: pointer; width: 100%; height: 100%"
        />
      </div>

      <div class="tab-list">
        <div class="tab" v-for="(item, index) in $t('lang.tabs')" :key="index">
          <el-dropdown @command="handleCommand" placement="bottom">
            <span class="tab-item">
              <router-link
                :to="{ path: item.path }"
                :style="{
                  borderBottom:
                    active === item.active ? '4px solid #37dc9c' : '',
                }"
              >
                {{ item.title }}
              </router-link>
              <i
                class="el-icon-arrow-down el-icon--right"
                v-if="index === 2"
              ></i>
            </span>
            <el-dropdown-menu style="visibility: hidden"></el-dropdown-menu>
            <el-dropdown-menu slot="dropdown" v-if="index === 2">
              <el-dropdown-item command="a">{{
                $t("lang.headerBusiness.titleOne")
              }}</el-dropdown-item>
              <el-dropdown-item command="b">{{
                $t("lang.headerBusiness.titleTwo")
              }}</el-dropdown-item>
              <el-dropdown-item command="c">{{
                $t("lang.headerBusiness.titleThree")
              }}</el-dropdown-item>
              <el-dropdown-item command="d">{{
                $t("lang.headerBusiness.titleFour")
              }}</el-dropdown-item>
              <el-dropdown-item command="e">{{
                $t("lang.headerBusiness.titleFive")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="top_btn" @click="changeLangEvent">中/En</div>
    </div>
  </div>
</template>

<script>
import chaLogo from "@/assets/logo.jpg";
import enLogo from "@/assets/eng_header.jpg";
export default {
  name: "webHeader",
  data() {
    return {
      chaLogo,
      enLogo,
      logo: "",
      active: 0,
      activeName: "first",
      isfixed: "",
      tabs: [
        { title: "首页", path: "/home", active: 0 },
        { title: "关于我们", path: "/aboutUs", active: 1 },
        { title: "业务结构", path: "/commerceOperation", active: 2 },
        { title: "拓新", path: "/expansion", active: 3 },
        { title: "品牌中心", path: "/brandCenter", active: 4 },
        { title: "加入我们", path: "/joinUs", active: 5 },
      ],
    };
  },
  created() {
    const path = this.$route.path;
    switch (path) {
      case "/home":
        this.active = 0;
        break;
      case "/aboutUs":
        this.active = 1;
        break;
      case "/commerceOperation":
        this.active = 2;
        break;
      case "/expansion":
        this.active = 3;
        break;
      case "/brandCenter":
        this.active = 4;
        break;
      case "/joinUs":
        this.active = 5;
        break;
      case "/technologyCenter":
        this.active = 2;
        break;
      case "/trade":
        this.active = 2;
        break;
      case "/supplyService":
        this.active = 2;
        break;
      case "/customerService":
        this.active = 2;
        break;
      case "/applyJoin":
        this.active = 2;
        break;
    }
    window.addEventListener("scroll", this.scrollToTop);
    const language = sessionStorage.getItem("language") || "zh-CN";
    if (language === "zh-CN") {
      this.logo = chaLogo;
    } else {
      this.logo = enLogo;
    }
  },
  methods: {
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 0) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    changeLangEvent() {
      const language = sessionStorage.getItem("language") || "zh-CN";
      this.$router.go(0);
      if (language === "zh-CN") {
        this.$i18n.locale = "en-US"; //关键语句
        this.logo = enLogo;
        sessionStorage.setItem("language", "en-US");
      } else {
        this.$i18n.locale = "zh-CN"; //关键语句
        this.logo = chaLogo;
        sessionStorage.setItem("language", "zh-CN");
      }
      window.pageYOffset =
        document.documentElement.scrollTop =
        document.body.scrollTop =
          0;
      this.scrollTop = 0;
    },
    handleCommand(command) {
      switch (command) {
        case "a":
          this.$router.push({ path: "/commerceOperation" });
          break;
        case "b":
          this.$router.push({ path: "/trade" });
          break;
        case "c":
          this.$router.push({ path: "/technologyCenter" });
          break;
        case "d":
          this.$router.push({ path: "/supplyService" });
          break;
        case "e":
          this.$router.push({ path: "/customerService" });
          break;
        case "f":
          this.$router.push({ path: "/applyJoin" });
          break;
      }
    },
  },
};
</script>
<style lang="scss">
.toFixed {
  background: #436aff;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .headerContainer {
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    width: 1200px;

    .chLogo {
      width: 43px;
      height: 25px;
    }
    .enLogo {
      width: 120px;
      height: 10px;
      margin-top: -10px;
    }
    .tab-list {
      display: flex;
      flex-direction: row;
      margin-left: 110px;
      width: 995px;
      justify-content: space-between;
      //align-items: center;
      .tab {
        padding: 21px 5px 25px;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        a {
          color: #ffffff;
          font-size: 14px;
          padding-bottom: 16px;
          white-space: nowrap;
        }
        a:hover {
          margin-top: -10px;
        }
        .el-icon--right {
          color: #ffffff;
        }
      }
      .tab:hover {
        margin-top: -2px;
        cursor: pointer;
        opacity: 0.5;
      }
    }
    .top_btn {
      width: 60px;
      height: 24px;
      border-radius: 25px;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 35px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
::v-deep .router-link-active,
a {
  font-size: 14px;
  color: #333333;
  text-decoration: none;
}
.el-dropdown {
  max-width: 100px !important;
}
.el-dropdown-menu__item {
  font-size: 14px;
  color: #8a9196;
}
.el-dropdown-menu__item:hover {
  background: none !important;
  color: #333333 !important;
}
</style>
