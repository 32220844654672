<template>
  <div class="homeBox">
    <div class="carousel">
      <pcHeader />
      <el-carousel
        :interval="5000"
        height="100vh"
        width="100vw"
        arrow="always"
        indicator-position="none"
      >
        <el-carousel-item>
          <div style="position: relative" class="world">
            <img
              src="../assets/word_back.jpg"
              style="width: 100%; height: 100%"
            />
            <div class="world-back">
              <img src="../assets/world.png" alt="" style="width: 70vh" />
              <div class="word-text">
                <div
                  class="to-world"
                  :class="{ toWorldEn: $i18n.locale === 'en-US' }"
                >
                  {{ $t("lang.home.titleOne") }}
                </div>
                <div class="hope" :class="{ hopeEn: $i18n.locale === 'en-US' }">
                  {{ $t("lang.home.titleTwo") }}
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="about">
      <div class="aboutimg">
        <img src="../assets/about.jpg" />
      </div>
      <div class="aboutDetail">
        <div class="title">{{ $t("lang.home.detail.title") }}</div>
        <div class="subTitle">
          {{ $t("lang.home.detail.subTitle") }}
        </div>
        <div class="container">
          {{ $t("lang.home.detail.container") }}
        </div>
        <router-link :to="{ path: '/aboutUs' }" class="tolearn">
          <div>{{ $t("lang.home.detail.more") }}<i class="triangle"></i></div>
        </router-link>
      </div>
    </div>
    <div class="ourService">
      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="20"
        shapeType="circle"
        :particleSize="8"
        linesColor="#28759f"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
      <div class="ourcontainer">
        <div class="advantage">{{ $t("lang.home.advantage.title") }}</div>
        <div class="partadvantage">
          <div class="part">
            <div class="partimg">
              <img src="../assets/1.png" />
            </div>
            <div class="titles">
              {{ $t("lang.home.advantage.itemOne.title") }}
            </div>
            <span>{{ $t("lang.home.advantage.itemOne.desc") }}</span>
          </div>
          <div class="part">
            <div class="partimg">
              <img src="../assets/2.png" />
            </div>
            <div class="titles">
              {{ $t("lang.home.advantage.itemTwo.title") }}
            </div>
            <span>{{ $t("lang.home.advantage.itemTwo.desc") }}</span>
          </div>
          <div class="part">
            <div class="partimg">
              <img src="../assets/3.png" />
            </div>
            <div class="titles">
              {{ $t("lang.home.advantage.itemThree.title") }}
            </div>
            <span>{{ $t("lang.home.advantage.itemThree.desc") }}</span>
          </div>
        </div>
        <div class="partadvantage">
          <div class="part">
            <div class="partimg">
              <img src="../assets/4.png" />
            </div>
            <div class="titles">
              {{ $t("lang.home.advantage.itemFour.title") }}
            </div>
            <span>{{ $t("lang.home.advantage.itemFour.desc") }}</span>
          </div>
          <div class="part">
            <div class="partimg">
              <img src="../assets/5.png" />
            </div>
            <div class="titles">
              {{ $t("lang.home.advantage.itemFive.title") }}
            </div>
            <span>{{ $t("lang.home.advantage.itemFive.desc") }}</span>
          </div>
        </div>
        <!--        <router-link :to="{ path: '/customerService' }" class="ourser">-->
        <!--          <div>{{ $t("lang.home.advantage.service") }}<i class="triangle"></i></div>-->
        <!--        </router-link>-->
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
  created() {
    this.lang = this.$i18n.locale;
  },
  data() {
    return {
      lang: "",
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.homeBox {
  .carousel {
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      //background-color: #d3dce6;
    }
    .el-carousel__arrow {
      border-radius: 0;
    }
  }
  .world {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;

    .world-back {
      position: absolute;
      width: 70vh;
      margin-top: 9vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .word-text {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;

        .to-world {
          color: #fff;
          font-size: 6vh;
          font-weight: bold;
          text-shadow: 0 0 25px #153a72;
        }

        .toWorldEn {
          color: #fff;
          font-size: 4.7vh;
          font-weight: bold;
          text-shadow: 0 0 25px #153a72;
        }

        .hope {
          color: #fff;
          font-size: 2.6vh;
          letter-spacing: 5px;
          margin-top: 1.8vh;
          text-shadow: 0 0 15px #001d58;
        }
        .hopeEn {
          color: #fff;
          font-size: 2.3vh;
          margin-top: 2.6vh;
          letter-spacing: 0;
          text-shadow: 0 0 15px #001d58;
        }
      }
    }
  }
  .about {
    width: 1200px;
    margin: 0 auto;
    margin-top: 120px;
    display: flex;
    flex-direction: row;
    .aboutimg {
      width: 520px;
      height: 385px;
      margin-right: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .aboutDetail {
      width: 610px;
      .title {
        font-size: 36px;
        font-weight: bold;
      }
      .subTitle {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0;
      }
      .container {
        font-size: 18px;
        margin-top: 20px;
        color: #6d7276;
      }
      .tolearn {
        width: 160px;
        padding: 17px 35px 18px;
        display: block;
        text-align: left;
        border: 2px solid #279cd4;
        border-radius: 6px;
        color: #279cd4;
        transition: all 0.2s ease 0s;
        position: relative;
        margin-top: 57px;
        cursor: pointer;
        font-size: 14px;
        text-decoration: none;
        .triangle {
          background: url("../assets/arrow.png");
          background-size: contain;
          position: absolute;
          top: 24px;
          right: 33px;
          width: 6px;
          height: 8px;
          line-height: 0;
          border-width: 6px 4px;
          font-size: 0;
          overflow: hidden;
          font-weight: normal;
        }
      }
      .tolearn:hover {
        background-color: #279cd4;
        color: #ffffff;
        .triangle {
          color: #ffffff;
          width: 6px;
          height: 8px;
          background: url("../assets/arrowhite.png");
          background-size: contain;
          margin-right: 5px;
        }
      }
    }
  }

  .ourService {
    margin-top: 140px;
    padding-bottom: 300px;
    background: #004d92;
    position: relative;
    height: 750px;
    .ourcontainer {
      width: 1200px;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      transform: translateX(-50%);
      z-index: 99;
      .advantage {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        padding-top: 132px;
        margin-bottom: 20px;
      }
      .partadvantage {
        display: flex;
        flex-direction: row;
        .part {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: calc((100% - 96px) / 3);
          .partimg {
            margin: 45px 0 20px;
          }
          .titles {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            height: 80px;
            line-height: 28px;
          }
          span {
            height: 100px;
            font-size: 14px;
            opacity: 0.7;
            letter-spacing: 0;
            line-height: 26px;
            display: inline-block;
            text-align: justify;
            color: #ffffff;
          }
        }
        .part:nth-child(3n + 1),
        .part:nth-child(3n + 2) {
          margin-right: 48px;
        }
      }
    }
    .ourser {
      width: 160px;
      padding: 17px 35px 18px;
      display: block;
      text-align: left;
      border: 2px solid #ffffff;
      border-radius: 6px;
      font-size: 14px;
      color: #ffffff;
      transition: all 0.2s ease 0s;
      position: relative;
      margin-top: 57px;
      cursor: pointer;
      text-decoration: none;
      .triangle {
        background-size: contain;
        position: absolute;
        top: 24px;
        right: 33px;
        width: 6px;
        height: 8px;
        line-height: 0;
        border-width: 6px 4px;
        font-size: 0;
        overflow: hidden;
        font-weight: normal;
        background: url("../assets/arrowhite.png");
        background-size: contain;
      }
    }
    .ourser:hover {
      background: #279cd4;
      color: #ffffff;
      border-color: #279cd4;
      .triangle {
        margin-right: 5px;
      }
    }
  }
}
</style>
